<template>
<section class="wrapper bg-light">
	<div class="container py-14 py-md-16">
		<div class="row align-items-center mb-10">

			<h2 class="display-4 mb-0">
				Vendre des fcEURO contre des Lumens (xlm) </h2>
		</div>
		<h2>
			Principe de base</h2>

		<p>
			L'achat et la vente de token en cryptomonnaie sur la blockchain stellar se fait en Lumens. Cette cryptomonnaie a pour sigle XLM.
			Elle est aussi utilisée pour payer les commissions de transactions.
			<br />
			<br />
			Pour faciliter les échanges et les conversions de tokens tout en conservant un référentiel financier facilement compréhensible
			par les utilisateurs, les sociétés qui participent à l'écosystème fraction.re ont émis des tokens reprenant la valeur de la monnaie fiat.
			<br /> <br />Pour les utilisateurs européens, il s'agit du fcEURO ( sigle fcEUR), pour les américains le fcDollars (sigle fcUSD ) ou pour les anglais le fcPound (sigle fcGBP ).
			<br />
			<br /> Lorsque l'on à revendu ses tokens en fcEURO on peut les revendre pour obtenir des Lumens.

			Ces Lumnes peuvent alors facilement être eux-même converti en Bitcoin, Ether ou autre sur des plateformes d'échanges comme Binance, CoinHouse...

		</p>
		<h2>
			Un exemple concret</h2>
		<p>
			Thomas veut vendre 1000 fcEURO
			<br />
		</p>

		<h3 class="mt-12">
			Situation initiale</h3>

		<div class="row ">
			<div class="col-6 col-md-6 mb-4">
				<img style="border: 1px solid silver; max-width:100%"
						 src="https://media.berlcoin.fr/sell-fceuro-1.png" />
				<br /> <br /> <b> Le compte de Thomas</b><br />
			</div>

		</div>


		<h3 class="mt-12">
			Ordre de vente de 1000 fcEURO </h3>


		<p>Dans la section <b>Vente</b>.</p>


		<div class="row ">
			<div class="col-6 col-md-6 mb-4">
				<img style="border: 1px solid silver; max-width:100%"
						 src="https://media.berlcoin.fr/sell-fceuro-2.png" />
				<br /> <br />
				<b>Thomas sélectionne le menu "Vente", la liste des tokens dont il dispose s'affiche
				</b> <br />
			</div>
			<div class="col-6 col-md-6  mb-4"><img style="border: 1px solid silver ; max-width:100%"
						 src="https://media.berlcoin.fr/sell-fceuro-3.png" /><br />
				<br /> <br />
				<b>Thomas sélectionne le fcEURO, le menu des ventes s'affiche et il appuit sur le bouton ORDRE DE VENTE.
				</b> <br />
			</div>

			<div class="col-6 col-md-6  mb-4"><img style="border: 1px solid silver ; max-width:100%"
						 src="https://media.berlcoin.fr/sell-fceuro-4.png" /><br />
				<br /> <br />
				<b> Le formulaire s'affiche, Thomas saisie la quantité de fcEURO qu'il souhaite vendre, conserve le cours actuel et valide.
				</b> <br />
			</div>
			<div class="col-6 col-md-6  mb-4"><img style="border: 1px solid silver ; max-width:100%"
						 src="https://media.berlcoin.fr/sell-fceuro-5.png" /><br />
				<br /> <br />
				<b>Le compte de Thomas a été actualisé, il a 1000 fcEURO en moins et 5000 XLM en plus.
				</b> <br />
			</div>


		</div>

		<div class="row ">
			<h3 class="mt-12">
				Coût de l'opération</h3>
			<p> Thomas a payé 0.00001 XLM (ici à 0.21 euro pour 1 XLM ) pour l'ordre de vente
				<br /> <br /> Au final moins de 1 centimes d'euro.
			</p>
		</div>

		<div class="row ">
			<h3 class="mt-12">
				A savoir sur les montants affichés</h3>
			<p> Le montant de réception de Lumnens affiché lors de la soumission du formulaire et le montant minimal.
				Si des ordres à meilleurs prix sont disponibles, ce sont ces offres qui seront prioritairement exécutés.
				<br /> <br /> Dans l'exemple ci dessus, le cours était à 0.21 mais il existait encore des ordres à 0.20 dont a bénéficié Thomas, il a donc reçu 5000 Lumes.
			</p>
			<h3 class="mt-12">
				A savoir sur les ordres non exécutés</h3>
			<p>Vous êtes bien évidemment libre de modifier le cours de change. Si aucun ordre en achat n'est disponible, celui-ci sera mis en attente,
				jusqu'à ce qu'il y ait des offres au cours correspondent à celui que vous avez indiqué. Dans ce cas, l'ordre est dans la rubrique ACHAT,
				car dans la blockchain stellar, lorsque l'on passe un ordre de vente ou d'achat d'un token contre un autre, il est
				enregistré en fonction de l'ordre alphabetique du nom des tokens. Donc une vente de Lumens (XLM) contre des fcEURO est enregistré comme un ordre d'achat de Lumens.
			</p>

		</div>
	</div>

</section>
</template>


<script>
export default {
	name: 'account',
	components: {},
	props: {},
	data: () => ({}),
	beforeCreate: function () {},
	created: function () {},
	beforeMount: function () {},
	mounted: function () {},
	beforeUpdate: function () {},
	updated: function () {},
	beforeDestroy: function () {},
	destroyed: function () {},
	computed: {},
	methods: {},
	watch: {}
}
</script>

<style scoped>
</style>
